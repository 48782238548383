<template>
  <div>
    <b-card no-title>
      <b-card-title>
        {{ $t('card.title.add.page') }}
      </b-card-title>
      <b-card-text>
        {{ $t('card.description.add.page') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.title')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.title').toLowerCase()"
                    rules="required"
                    vid="title"
                >
                  <b-form-input
                      v-model="formData.title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.title').toLowerCase()"
                      @input="titleChanged"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.slug')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.slug').toLowerCase()"
                    rules="required"
                    vid="slug"
                >
                  <b-form-input
                      v-model="formData.slug"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.slug').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.menuTitle')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.menuTitle').toLowerCase()"
                    rules="required"
                    vid="menuTitle"
                >
                  <b-form-input
                      v-model="formData.menuTitle"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.menuTitle').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.subtitle')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.subtitle').toLowerCase()"
                    rules="required"
                    vid="subtitle"
                >
                  <b-form-input
                      v-model="formData.subtitle"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.subtitle').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.content')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.content').toLowerCase()"
                    rules="required"
                    vid="pageContent"
                >
                  <b-link
                      @click="$bvModal.show('bv-modal-post-help')"
                  >
                    <small class="text-primary cursor-pointer">* "Speciális jelölések" súgó</small>
                  </b-link>
                  <editor :value="formData.pageContent" @input="contentUpdated"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.showInTopMenu').toLowerCase()"
                    rules=""
                    vid="showInTopMenu"
                >
                  <b-form-checkbox v-model="formData.showInTopMenu"
                                   :state="errors.length > 0 ? false:null"
                  >
                    {{ $t('form.showInTopMenu') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.showInBottomMenu').toLowerCase()"
                    rules=""
                    vid="showInBottomMenu"
                >
                  <b-form-checkbox v-model="formData.showInBottomMenu"
                                   :state="errors.length > 0 ? false:null"
                  >
                    {{ $t('form.showInBottomMenu') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                {{ $t('label.button.save') }}
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
      <help-modal/>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTextarea, BLink
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import helpModal from "@/views/pages/_components/_modal/areaCode/help.vue";
import editor from "@/views/pages/_components/_helper/editor.vue";

export default {
  components: {
    helpModal,
    BLink, BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    cancelAndGOTOPreviousPage,
    editor,
  },
  data() {
    return {
      role: 'page',
      formData: {
        menuTitle: '',
        title: '',
        subtitle: '',
        slug: '',
        pageContent: '',
        showInTopMenu: false,
        showInBottomMenu: false,
      },
      loading: false,
      required,
    }
  },
  methods: {
    titleChanged() {
      this.formData.slug = this.$helpers.slugify(this.formData.title)
    },
    contentUpdated(value) {
      this.formData.pageContent = value
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('storePage', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'modify-page', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
